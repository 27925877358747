import React from "react";
import Logo from "../images/logos/ugec-logo.svg";
import { Helmet } from "react-helmet";

const ThankYouGeneral = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>United Green Energy Credits | Thank You</title>

        <meta
          name="description"
          content="United Green Energy can provide green energy to customers in a multitude of ways from many renewable energy services and products to offsetting credits. Check out our services pages to learn more about how to go green with UGE! "
        />
        <link
          rel="icon"
          href="https://imgix.cosmicjs.com/5ffde510-a9f1-11ec-bd4d-d755cb3d1844-UGE-Icon-Green-01.png"
        />
        <link
          rel="icon"
          href="https://imgix.cosmicjs.com/5ffde510-a9f1-11ec-bd4d-d755cb3d1844-UGE-Icon-Green-01.png"
        />
      </Helmet>
      <main class="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-12 lg:mt-16">
        <div class="text-center">
          <img class="mx-auto w-1/2" src={Logo} alt="logo" />
          <div className="mx-auto flex w-6/12 mb-4"></div>
          <h2 class="mt-8 text-2xl md:text-4xl tracking-tight md:leading-12 font-extrabold text-emerald-700 sm:text-5xl sm:leading-none md:text-6xl">
            Your Submission was received successfully!
          </h2>
          <p class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            One of our representatives at United Green Energy will get in touch
            with you in 24-48 hours if needed, thank you for your patience.
          </p>

          <div class="rounded-md">
            <div class="mt-10 rounded-md sm:flex-shrink-0">
              <a
                href="/"
                class="w-full px-16 py-5 text-lg font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-800 focus:outline-none focus:ring-neonGreen"
              >
                Return Home
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ThankYouGeneral;
